<template>
  <div class="progress__bar">
    <div class="d-flex justify-content-between align-items-center">
      <label :for="`progress-${name}`" class="font-weight-bolder">{{
        title
      }}</label>
      <router-link v-if="redirectPath" class="view__detail" :to="redirectPath"
        >Xem chi tiết</router-link
      >
    </div>
    <v-progress-linear
      :id="`progress-${name}`"
      :value="value"
      height="30"
      rounded
      background-opacity="1"
      background-color="#F2F3F7"
      color="#008479"
    >
    </v-progress-linear>
    <div class="wrap__value">
      <div class="completed__target">
        <div
          v-if="disableCompletedTarget"
          :style="{ width: `${value}%` }"
          class="h-100 d-flex align-items-center justify-content-end"
        >
          {{ completedTarget }}
        </div>
      </div>
      <div class="original__target h-100 d-flex align-items-center">
        {{ originalTarget }}
      </div>
    </div>
  </div>
</template>
<script>
import round from 'lodash/round';
export default {
  name: 'Progressbar',
  props: {
    name: {
      type: String,
      default: null,
    },
    completedTarget: {
      type: Number,
      default: 0,
    },
    originalTarget: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: null,
    },
    redirectPath: {
      type: String,
      default: null,
    },
  },
  computed: {
    value() {
      if (!this.originalTarget) return 0;
      return round((this.completedTarget / this.originalTarget) * 100);
    },
    disableCompletedTarget() {
      const percent = 100 - this.value;
      return Math.floor(percent) >= 2;
    },
  },
  mounted() {
    const progress = document.querySelector(
      `#progress-${this.name} .v-progress-linear__determinate`,
    );

    if (this.value) {
      const percentNumber = document.createElement('span');
      percentNumber.innerText = `${round(this.value, 2)}%`;
      percentNumber.style.color = '#fff';
      progress.appendChild(percentNumber);
    }
  },
};
</script>
<style lang="scss" scoped>
.wrap__value {
  height: 30px;
  display: grid;
  grid-template-columns: 1fr max-content;
  position: relative;
}

.original__target {
  position: absolute;
  right: -8px;
}

.progress__bar {
  width: 85%;
}

.view__detail {
  font-weight: 600;
  font-size: 14px;
  line-height: 132%;
  color: #008479;
}
</style>
<style lang="scss">
.v-progress-linear__determinate {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
